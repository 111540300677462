const ENDPOINTS = {
  USER: {
    LOGIN: '/login/',
    REGISTER: '/register/',
    WHOAMI: '/users/whoami/',
    CREATE_SUBSCRIPTION_INTENT: (id, freqString) =>
      '/users/create-subscription-intent/' + id + '/' + freqString,
    CANCEL_SUBSCRIPTION: '/users/cancel-subscription/',
    FREE_SUBSCRIBE: '/users/create-free-subscription/',
    REVISE_SUBSCRIPTION: '/users/revise-subscription/'
  }
};

export default ENDPOINTS;
