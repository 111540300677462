import ENDPOINTS from "../endpoints";
import apiService from "./api.service";

export default class UserService {
  static async login(email, password) {
    const res = await apiService.post(ENDPOINTS.USER.LOGIN, {
      email,
      password,
    });
    return res.data;
  }

  static async register(email, password, riot_name, tagline, region) {
    const res = await apiService.post(ENDPOINTS.USER.REGISTER, {
      email,
      password,
      riot_name: riot_name,
      tagline,
      region,
    });
    return res.data;
  }

  static async whoami() {
    const res = await apiService.get(ENDPOINTS.USER.WHOAMI);
    return res.data;
  }

  static async createPaymentIntent(id, freqString) {
    const res = await apiService.post(
      ENDPOINTS.USER.CREATE_SUBSCRIPTION_INTENT(id, freqString)
    );
    return res.data;
  }

  static async cancelSubscription() {
    const res = await apiService.patch(ENDPOINTS.USER.CANCEL_SUBSCRIPTION);
    return res.data;
  }

  static async freeSubscribe() {
    const res = await apiService.post(ENDPOINTS.USER.FREE_SUBSCRIBE);
    return res.data;
  }

  static async reviseSubscription(planId) {
    const appBaseUrl = window.location.origin;
    const body = {
      application_context: {
        return_url: `${appBaseUrl}/user/profile`,
        cancel_url: `${appBaseUrl}/user/profile`,
      },
      plan_id: planId,
    };
    const res = await apiService.patch(
      ENDPOINTS.USER.REVISE_SUBSCRIPTION,
      body
    );
    return res.data;
  }
}
