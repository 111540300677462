import Hero from "./sections/Hero";
import SampleReport from "./sections/SampleReport";
import Features from "./sections/Features";
import HowItWorks from "./sections/HowItWorks";
import PlanCards from "../../Components/PlanCards";

const LandingPage = () => {
  return (
    <div className="max-w-[1920px] mx-auto">
      <Hero />
      <SampleReport />
      <Features />
      <HowItWorks />
      <div className="flex mt-28 pb-48 w-full justify-center">
        <PlanCards />
      </div>
    </div>
  );
};

export default LandingPage;
