import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AuthProvider } from "./context/auth.context";
// import Header from "./Components/Layout/Header";
// import Footer from "./Components/Layout/Footer";
// import HomePage from "./Components/Home/HomePage";
// import ProfilePage from "./Components/Profile/ProfilePage";
// import AllArticles from "./Components/AllArticles/AllArticles";
// import Pricing from "./Components/Pricing/Pricing";
import { toast } from "react-toastify";
import Login from "./Components/Auth/Login";
import Register from "./Components/Auth/Register";
import Layout from "./Components/NewDesign/Layout";
import "./Components/NewDesign/layout.css";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Plans from "./Pages/Plans";
import Checkout from "./Pages/Checkout";
import ManageProfile from "./Pages/ManageProfile";

const App = () => (
  <AuthProvider>
    <ToastContainer theme="dark" position={toast.POSITION.BOTTOM_LEFT} />
    <Router><Routes>

        <Route exact path="/" element={<Layout />}>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/plans" element={<Plans />} />
          <Route exact path="/checkout/:id" element={<Checkout />} />
          <Route exact path="/user/profile" element={<ManageProfile />} />
        </Route>

      </Routes></Router>
  </AuthProvider>
);

export default App;
