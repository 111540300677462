import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth.context.jsx";
import wrapPromiseWithToast from "../../utils/wrapPromiseWithToast.js";
import Input from "../Input/index.jsx";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const onLogin = async () => {
    if (!recaptchaVerified) return;
    await wrapPromiseWithToast(login(email, password), {
      pending: "Logging in...",
      success: "Logged in successfully!",
      error: (err) =>
        err.response?.data?.detail?.toString?.() ||
        err.reponse?.data?.details?.[0]?.toString?.() ||
        "Error registering!",
    });
  };

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <h1 className="text-white text-3xl font-semibold">Sign in</h1>
      <div className="flex flex-col mt-8 gap-4 justify-center">
        <Input
          label="Email"
          type="email"
          className="rounded-2xl bg-opacity-60 bg-gray-900"
          labelClassName="text-lg"
          containerClassName={"md:w-[516px]"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          label="Password"
          type="password"
          className="rounded-2xl bg-opacity-60 bg-gray-900"
          labelClassName="text-lg"
          containerClassName={"md:w-[516px]"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mt-6 mb-4 w-full flex justify-center">
          <ReCAPTCHA
            sitekey="6LeDYyEpAAAAAImLmIJokox6RaOKy7DKMSeMqIDa"
            onChange={() => setRecaptchaVerified(true)}
            theme="dark"
          />
        </div>
        <div className="flex justify-center">
          <button
            className="bg-yellow-600 w-fit flex items-center justify-center gap-2 px-12 py-1.5 rounded-2xl text-center h-[38.7px] font-normal text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={onLogin}
            disabled={!recaptchaVerified}
          >
            Sign in
            <FaArrowRight className="w-4 font-light" />
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center mt-8 gap-4 text-white">
        {/* <Link to={"#"}>Forgot password?</Link>
        <span className="bg-white w-[1.5px] h-7"></span> */}
        <Link className="text-sm py-2 px-4" to={"/register"}>Create an Account</Link>
      </div>
    </div>
  );
}
